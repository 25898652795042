<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['facialAcneCoverage'])">
      <single-picture-select
        title="label.facialAcneCoverage"
        :gender="gender"
        :value="facialAcneCoverage"
        :options="$options.coverageOptions"
        @input="onFieldChange('facialAcneCoverage', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SinglePictureSelect from '@/modules/questionnaire/new-design-components/questions/SinglePictureSelect';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';
import { COVERAGE_OPTIONS } from '@/modules/questionnaire/constants/steps/pimples';

export default {
  name: 'CoverageTemplate',
  components: { QuestionWithErrorWrap, SinglePictureSelect },
  mixins: [stepTemplateMixin],
  coverageOptions: COVERAGE_OPTIONS,
  props: {
    gender: {
      type: String,
      required: true
    },
    facialAcneCoverage: {
      type: String,
      default: ''
    }
  }
};
</script>
